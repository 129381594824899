import React from 'react'
import FieldInput from 'common/components/FieldInput'
import { Row, Col } from 'antd'
import FormField from 'common/components/FormField'

const UserInformationForm: React.FC = () => {
  return (
    <>
      <Row justify='space-between'>
        <Col span={11}>
          <FormField
            label='First Name'
            name='firstName'
            placeholder='Type-in name'
            component={FieldInput}
            required
          />
        </Col>
        <Col span={11}>
          <FormField
            label='Last Name'
            name='lastName'
            placeholder='Type-in last name'
            component={FieldInput}
            required
          />
        </Col>
      </Row>
      <FormField
        label='E-mail'
        name='partnerEmail'
        placeholder='name@domain.com'
        component={FieldInput}
        required
      />
    </>
  )
}
export default UserInformationForm
