import styled from 'styled-components'
import { Input as InputBase } from 'antd'


export const Input = styled(InputBase)`
  && {
    border-radius: 25px;
  }
  input {
    border-radius: 25px;
  }

  .ant-input-group-addon {
    background: transparent;
    padding: 0;
    border: none;
    margin: 0;
    z-index: 1;
    button {
      background: ${({ theme }) => theme.colours.primary};
      border-color: ${({ theme }) => theme.colours.primary};
      color: ${({ theme }) => theme.colours.primaryText};
      border-radius: 20px;
      margin-left: -15px;
      box-shadow: none;
      &.ant-btn-danger {
        background: ${({ theme }) => theme.colours.bgDark};
        border-color: ${({ theme }) => theme.colours.inputBorder};
        color: ${({ theme }) => theme.colours.danger};
      }
    }
  }
`