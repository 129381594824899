import React from 'react'
import { Form } from 'antd'
import { FormTitle, FormDescription, PasswordDescription } from '../styledComponents'
import { Button } from 'common/components/Button'
import { InputIcon } from 'common/components/InputIcon'
import { Formik, Field } from 'formik'
import validationsSchema from './validationsSchema'
import FieldInput from 'common/components/FieldInput'
import { Link } from 'react-router-dom'
import { RightOutlined } from '@ant-design/icons'

interface SignInProps {
  login: (username: string, password: string) => void
  isSubmitting: boolean
}

const SignIn: React.FC<SignInProps> = ({ login, isSubmitting }) => {

  const handleLogin = (username: string, password: string) => {
    login(username, password)
  }

  return (
    <div>
      <FormTitle>Welcome to Phaze.</FormTitle>
      <FormDescription>
        Enter your email address and password to access your account.
      </FormDescription>
      <Formik
        initialValues={{ login: '', password: '' }}
        onSubmit={values => handleLogin(values.login, values.password)}
        validationSchema={validationsSchema}
      >
        {({ handleSubmit }) => (
          <Form onFinish={handleSubmit}>
            <Field
              name='login'
              placeholder='Login'
              component={FieldInput}
              icon={<InputIcon type='UserOutlined' />}
            />
            <Field
              name='password'
              placeholder='Password'
              component={FieldInput}
              icon={<InputIcon type='SafetyOutlined' />}
              type='password'
            />
            <Button
              type='primary'
              shape='round'
              htmlType='submit'
              disabled={isSubmitting}
              data-testid='test-login'
            >
              Login
              <RightOutlined />
            </Button>
            <PasswordDescription>
              If you want to reset your password,{' '}
              <Link to='/reset-password'>click here</Link>.
            </PasswordDescription>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default SignIn
