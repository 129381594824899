import { Dispatch } from '@reduxjs/toolkit'
import { openNotification } from 'common/components/openNotification'
import {
  createAccountRequest,
  createAccountSuccess,
  createAccountFailure,
  setApiKeyRequest,
  setApiKeySuccess,
  setApiKeyFailure,
  setTemporaryDisableAccountRequest,
  setTemporaryDisableAccountRequestSuccess,
  setTemporaryDisableAccountRequestFailure,
  setDisableSaaSSubscriptionAccountRequest,
  setDisableSaaSSubscriptionAccountRequestSuccess,
  setDisableSaaSSubscriptionAccountRequestFailure,
  setSaaSSubscriptionAccountRequest,
  setSaaSSubscriptionAccountRequestSuccess,
  setSaaSSubscriptionAccountRequestFailure,
  setSecretRequest,
  setSecretSuccess,
  setSecretFailure,
  fetchAccountsRequest,
  fetchAccountsSuccess,
  fetchAccountsFailure,
  fetchAccountByIdRequest,
  fetchAccountByIdSuccess,
  fetchAccountByIdFailure,
  updateAccountByIdRequest,
  updateAccountByIdSuccess,
  updateAccountByIdFailure,
  setActiveAccount,
  archivePartnerRequest,
  archivePartnerSuccess,
  archivePartnerRequestFailure,
  deleteWebHookRequest,
  deleteWebHookSuccess,
  deleteWebHookFailure
} from './slice'
import { client } from 'common/api/client'
import { URL_ACCOUNTS, URL_GENERATE_API_KEYS,URL_ARCHIVE_PARTNER, URL_ACCOUNTS_WEBHOOKS, URL_DISABLE_SAAS_MODEL_FOR_ACCOUNT, URL_CREATE_UPDATE_SAAS_MODEL_FOR_ACCOUNT } from 'common/api/paths'
import { SaaSSubscriptionModel } from '../schema/accounts'

export enum Mode {
  Generate = 'generate',
  Revoke = 'revoke',
  DisableAccount = 'disableAccount',
  EnableAccount = 'enableAccount'
}

export enum SaaSSubscriptionMode {
  DisableSaaSSubscription = 'DisableSaaSSubscription',
  EnableSaaSSubscription = 'EnableSaaSSubscription',
}

export enum SaaSSubscriptionKey {
  TopUp = 'topup',
  Voucher = 'voucher',
}

export const createAccount = (form: object) => async (dispatch: Dispatch) => {
  try {
    dispatch(createAccountRequest())
    const res = await client.post<any>(URL_ACCOUNTS, form)

    dispatch(createAccountSuccess(res.data))
    openNotification(res.data.message, 'success')
  } catch (err: any) {
    const errorMessage = err.response?.data.error || err.toString()
    dispatch(createAccountFailure(errorMessage))
    openNotification(errorMessage, 'error')
  }
}

export const setApiKey = (id: number, mode: Mode) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(setApiKeyRequest())
    const res = await client.post<any>(URL_GENERATE_API_KEYS(id), {
      mode,
      key: 'api_key'
    })
    dispatch(setApiKeySuccess({ ...res.data, id, mode }))
    openNotification(`Successfully ${mode + 'd'} api key`, 'success')
  } catch (err: any) {
    const errorMessage = err.response?.data.error || err.toString()
    dispatch(setApiKeyFailure(errorMessage))
    openNotification(errorMessage, 'error')
  }
}

export const setAccountDisable = (id: number, mode: Mode) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(setTemporaryDisableAccountRequest())
    const res = await client.post<any>(URL_GENERATE_API_KEYS(id), {
      mode,
      key: 'disabled'
    })
    dispatch(setTemporaryDisableAccountRequestSuccess({ ...res.data, id, mode }))
    openNotification(`Successfully updated`, 'success')
  } catch (err: any) {
    const errorMessage = err.response?.data.error || err.toString()
    dispatch(setTemporaryDisableAccountRequestFailure(errorMessage))
    openNotification(errorMessage, 'error')
  }
}

export const setSecret = (id: number, mode: Mode) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(setSecretRequest())
    const res = await client.post<any>(URL_GENERATE_API_KEYS(id), {
      mode,
      key: 'request_secret'
    })

    dispatch(setSecretSuccess({ ...res.data, id }))
    openNotification(`Successfully ${mode + 'd'} new secret`, 'success')
  } catch (err: any) {
    const errorMessage = err.response?.data.error || err.toString()
    dispatch(setSecretFailure(errorMessage))
    openNotification(errorMessage, 'error')
  }
}

export const fetchAccounts = (
  currentPage?: number,
  perPage?: number,
  organizationName?: string
) => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchAccountsRequest())
    const params = {
      currentPage,
      perPage,
      organizationName
    }
    const res = await client.get<any>(URL_ACCOUNTS, { params })
    dispatch(fetchAccountsSuccess(res.data))
  } catch (err: any) {
    const errorMessage = err.response?.data.error || err.toString()
    dispatch(fetchAccountsFailure(errorMessage))
  }
}

export const fetchAccountById = (id: number) => async (dispatch: Dispatch) => {
  try {
    dispatch(setActiveAccount(id))
    dispatch(fetchAccountByIdRequest())
    const res = await client.get(`${URL_ACCOUNTS}/${id}`)
    dispatch(fetchAccountByIdSuccess(res.data))
  } catch (err: any) {
    const errorMessage = err.response?.data.error || err.toString()
    dispatch(fetchAccountByIdFailure(errorMessage))
    openNotification(errorMessage, 'error')
  }
}

export const updateAccountById = (
  id: number,
  payload: { [key: string]: any }
) => async (dispatch: Dispatch) => {
  try {
    dispatch(setActiveAccount(id))
    dispatch(updateAccountByIdRequest())
    const res = await client.put<any>(`${URL_ACCOUNTS}/${id}`, payload)
    openNotification(
      `Successfully updated partner ${res?.data?.organizationName ||
        `with ID ${id}`}`,
      'success'
    )
    dispatch(updateAccountByIdSuccess(res.data))
  } catch (err: any) {
    const errorMessage = err.response?.data.error || err.toString()
    dispatch(updateAccountByIdFailure(errorMessage))
    openNotification(errorMessage, 'error')
  }
}

export const archivePartner = (accountId: number) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(archivePartnerRequest())
    await client.delete(URL_ARCHIVE_PARTNER(accountId))
    dispatch(archivePartnerSuccess(accountId))
    openNotification('Account has been deleted', 'success')
  } catch (err: any) {
    const errorMessage = err.response?.data.error || err.toString()
    dispatch(archivePartnerRequestFailure(errorMessage))
    openNotification(errorMessage, 'error')
  }
}

export const deleteWebHook = (webhookId: number, accountId: number) => async (
  dispatch: Dispatch
) => {
    try {
      dispatch(deleteWebHookRequest())
      await client.delete(`${URL_ACCOUNTS_WEBHOOKS(webhookId,accountId)}`)
      dispatch(deleteWebHookSuccess({webhookId,accountId}))
      openNotification('Webhook has been deleted', 'success')
    } catch (err: any) {
      const errorMessage = err.response?.data.error || err.toString()
      dispatch(deleteWebHookFailure(errorMessage))
      openNotification(errorMessage, 'error')
    }
}

export const setAccountSaaSDisable = (id: number,key : SaaSSubscriptionKey,mode: SaaSSubscriptionMode) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(setDisableSaaSSubscriptionAccountRequest())
    const res = await client.post<any>(URL_DISABLE_SAAS_MODEL_FOR_ACCOUNT(id), {
      mode,
      key
    })
    dispatch(setDisableSaaSSubscriptionAccountRequestSuccess({ ...res.data, id, mode }))
    openNotification(`Successfully updated`, 'success')
  } catch (err: any) {
    const errorMessage = err.response?.data.error || err.toString()
    dispatch(setDisableSaaSSubscriptionAccountRequestFailure(errorMessage))
    openNotification(errorMessage, 'error')
  }
}

export const updateSaaSSubscriptionForAccount = (id: number,subscriptionGroup: string,data : Array<SaaSSubscriptionModel>) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(setSaaSSubscriptionAccountRequest())
    const res = await client.post<any>(URL_CREATE_UPDATE_SAAS_MODEL_FOR_ACCOUNT(id,subscriptionGroup),data)
    dispatch(setSaaSSubscriptionAccountRequestSuccess({ ...res.data, id}))
    openNotification(`Successfully updated`, 'success')
  } catch (err: any) {
    const errorMessage = err.response?.data.error || err.toString()
    dispatch(setSaaSSubscriptionAccountRequestFailure(errorMessage))
    openNotification(errorMessage, 'error')
  }
}
