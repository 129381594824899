import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addProduct } from 'common/store/brandListFromType/actions'
import { RootState } from 'common/store/rootReducer'
import { appSelectors } from 'common/store/app/slice'
import AddPersistentList from './AddPersistentList'

const AddPersistentListContainer: React.FC<{ brandListType: string }> = ({ brandListType }) => {
  const dispatch = useDispatch()
  const brandCountryList = useSelector((state: RootState) => appSelectors.getBrandCountries(state))

  // const handleFetchBrandForSelectedType = (brandListType: string, currentPage?: number, perPage?: number, searchParams?: string) => {
  //   dispatch(fetchBrandForSelectedType(brandListType, currentPage, perPage, searchParams))
  // }

  // const handleDeleteProduct = (id: string, brandListType: string, currentPage?: number, perPage?: number, searchParams?: string) => {
  //   dispatch(deleteProduct(id, brandListType, currentPage, perPage, searchParams))
  // }

  const handleAddProduct = (updatedValue: any, brandListType: string, currentPage?: number, perPage?: number, searchParams?: string) => {
    return dispatch(addProduct(updatedValue, brandListType, currentPage, perPage, searchParams))
  }

  return (
    <AddPersistentList
      brandCountryList={brandCountryList}
      brandListType={brandListType}
      addProduct={handleAddProduct}
    />
  )
}

export default AddPersistentListContainer
