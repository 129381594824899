import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'common/store/rootReducer';

import ViewContainer from 'common/components/ViewContainer';
import TransactionsTable from 'core/TransactionsTable';

const Transactions: React.FC = () => {
  const organizationName = useSelector((state: RootState) => state.auth.organizationName ?? '');
  const role = useSelector((state: RootState) => state.auth.role);
  const url = `/me/transactions`;
  console.log(url);
  
  return (
    <ViewContainer title='Transactions'>
      <TransactionsTable role={role} fetchUrl={url} displayContainer organizationName={organizationName} />
    </ViewContainer>
  );
};

export default Transactions;
