import styled from 'styled-components'
import { Layout } from 'antd'

export const Container = styled.div`
  height: 100vh;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
`
export const MainSection = styled(Layout)`
  flex: 1;
  box-shadow: inset 20px 0 36px -20px rgba(0, 0, 0, 0.15);
  overflow: auto;
  background: #f5f5f5;
`
