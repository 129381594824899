import React, { ReactNode } from 'react'

import { FieldProps } from 'formik'

import { Input } from 'common/components/Input'
import { FormItem } from 'common/components/FormItem'

interface FieldInputProps extends FieldProps {
  label?: string
  placeholder?: string
  icon?: ReactNode
  suffix?: string
  required?: boolean
  type?: string
}
const FieldInput: React.FC<FieldInputProps> = ({
  form: { touched, errors, setFieldTouched, setFieldValue, values },
  field: { name },
  label,
  type,
  placeholder,
  icon,
  suffix,
  required
}) => {
  const errorMsg = touched[name] && errors[name]

  return (
    <>
      <FormItem
        help={errorMsg ? (errorMsg as React.ReactNode) : undefined}
        validateStatus={errorMsg ? 'error' : undefined}
        label={label}
        htmlFor={name}
        required={required}
      >
        <Input
          prefix={icon || undefined}
          suffix={suffix || undefined}
          type={type}
          name={name}
          title={label}
          placeholder={placeholder}
          onChange={e => {
            setFieldValue(name, e.target.value)
          }}
          onBlur={() => setFieldTouched(name)}
          value={values[name]}
          id={name}
        />
      </FormItem>
    </>
  )
}
export default FieldInput
