import { useCallback, useState } from 'react'
import { client } from 'common/api/client'
import { DateTime } from 'luxon'
import { FinanceDetails } from 'common/store/schema/dashboard'


const useFetchDashboard = (url: string) => {
  const dateTime = DateTime.utc()
  const defaultFinanceDetails: FinanceDetails = {
    balance: 0,
    transactions: 0,
    transactionsSucceeded: 0,
    transactionsFailed: 0,
    averageTransaction: 0,
    totalCommissions: 0,
    totalTransactions: 0,
    commissionsInSelectedRange: 0,
    salesInSelectedRange: 0,
    totalSales: 0,
    selectedRange: 0,
    averageEarnedPercentage: 0,
    displayCurrency: '',
    totaltransactionsFailed: 0,
    totaltransactionsSucceeded: 0,
    updateTime: dateTime.toFormat('yyyy-MM-dd h:mma'),
    holdingBalance: 0, 
    totalRevenueInRange: 0, 
    totalRevenue: 0, 
    transactionsCancelled: 0, 
    totalTransactionsCancelled: 0
  }

  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<FinanceDetails>(defaultFinanceDetails)
  const [error, setError] = useState(false)

  const fetchData = useCallback(
    (fromDate: string, toDate: string) => {
      console.log(url);
      setError(false)
      setIsLoading(true)
      const fetchData = async () => {
        try {
          const response = await client.get<FinanceDetails>(url, {
            params: {
              fromDate: fromDate,
              toDate: toDate
            }
          })

          setData({
            ...response.data,
            updateTime: dateTime.toFormat('yyyy-MM-dd h:mma')
          })
        } catch (err) {
          setError(true)
        }
        setIsLoading(false)
      }
      fetchData()
    },
    [url]
  )

  return {
    data,
    isLoading,
    error,
    fetchData
  }
}

export default useFetchDashboard
