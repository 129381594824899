import React, { useContext } from 'react'
import phazeEnvContext from 'common/contexts/phazeEnvContext'
import { Container, CurrentEnv, LinkButton, Text } from './styledComponents'

const EnvSwitcher = () => {
  const { phazeEnv, phazeEnvToGoTo, phazeEnvToGoToUrl } = useContext(
    phazeEnvContext
  )
  return (
    <Container>
      <Text>
        You are currently in the <CurrentEnv>{phazeEnv}</CurrentEnv>{' '}
        environment.{' '}
        </Text>
        <Text>
        <LinkButton type='primary' shape='round' href={phazeEnvToGoToUrl}>
          Switch to {phazeEnvToGoTo}
        </LinkButton>
      </Text>
    </Container>
  )
}

export default EnvSwitcher
