import styled from 'styled-components'

export const DashboardContainer = styled.div`
  padding: 35px 56px;
`
export const TodaySection = styled.div`
  margin-top: 25px;
`
export const OverviewSection = styled.div``
export const SectionHeader = styled.div`
  width: auto;
  display: flex;
  justify-content: space-between;
`
export const SectionTitle = styled.div`
  font-size: ${({ theme }) => theme.font.lg};
  font-weight: bold;
  display: inline;
`

export const SectionSubTitle = styled.p`
  margin: 0 0 0 15px;
  display: inline;
`

export const Filters = styled.div`
  width: auto;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  align-items: center;
  flex-wrap: wrap;
`
