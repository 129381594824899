import styled from 'styled-components'

export const HeaderContainer = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 25px;
`
export const Logo = styled.img`
  display: block;
  width: 106px;
`
export const DropDownTrigger = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.colours.primary};
`

export const Username = styled.span`
  padding: 0 20px;
`
export const ModeSwitch = styled.div`
  margin: 0 50px;
  color: ${({ theme }) => theme.colours.textDark};
  > span {
    margin-right: 10px;
  }
`
export const UserSettings = styled.div`
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
