import { Button,  Modal } from 'antd'
import React, { FC, useState } from 'react'

type IPersistentListDescriptionModalProps = {
    description: string
    howToUse: string
    terms: string
}

const PersistentListDetailsModals: FC<IPersistentListDescriptionModalProps> = ({ description, howToUse, terms }) => {
    const [open, setOpen] = useState(false)

    return (
        <>
            <Button onClick={() => setOpen(prev => !prev)}>View</Button>
            <Modal
                title='Add User'
                visible={open}

                onCancel={() => {
                    setOpen(prev => !prev)
                }}
                cancelText=""
                footer={null}
            >
                <>
                    <h1>Description</h1>
                    <div
                        // source is trusted
                        dangerouslySetInnerHTML={{ __html: description || '' }}
                    ></div>
                    <h1>How to use</h1>
                    <div
                        // source is trusted
                        dangerouslySetInnerHTML={{ __html: howToUse || '' }}
                    ></div>
                    <h1>Terms</h1>
                    <div
                        // source is trusted
                        dangerouslySetInnerHTML={{ __html: terms || '' }}
                    ></div>

                </>

            </Modal>
        </>
    )
}

export default PersistentListDetailsModals
