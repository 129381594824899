import React from 'react'
import styled from 'styled-components'
import { Button as ButtonBase } from 'antd'

export const Button = styled(({ compact, ...defaultProps }) => (
  <ButtonBase {...defaultProps} />
))<{ compact?: boolean }>`
  
  margin: ${({ compact }) => (compact ? '10px 5px' : '20px 10px')};

  padding: ${({ compact }) => (compact ? '5px 15px' : '10px 20px')};
  display: flex;
  align-items: center; 
  justify-content: center; 

  // margin: 30px 0;
  // span {
  //   padding: 0;
  //   margin-left: ${({ compact }) => (compact ? '8px' : '10px')}; 
  // }

  // ${props => (props.compact ? 'padding: 0 10px' : 'padding: 0 15px')};

  // span {
  //   ${props => (props.compact ? 'padding: 0 ' : 'padding: 0 13px')};
  // }

  &&:not([disabled]) {
    background-color: ${({ theme, type }) =>
      type === 'primary' && theme.colours.primary};
    border-color: ${({ theme, type }) =>
      type === 'primary' && theme.colours.primary};
  }

  &&:hover:not([disabled]),
  &&:focus:not([disabled]),
  &&:active:not([disabled]) {
    background-color: ${({ theme, type }) =>
      type === 'primary' && theme.colours.buttonHover};
    color: ${({ theme, type }) => type === 'default' && theme.colours.primary};
    border-color: ${({ theme, type }) =>
      (type === 'default' || type === 'primary') && theme.colours.buttonHover};
  }
`
