import React from 'react'
import { Field } from 'formik'
import { FieldWrapper, FieldLabel, FieldInputWrapper } from './styledComponents'

interface FormFieldProps {
  suffix?: string
  label: string
  name: string
  placeholder?: string | number
  component: React.ComponentType<any>
  options?: any[] // To handle dropdown options
  required?: boolean
  style?: React.CSSProperties
  icon?: React.ReactNode // To handle an icon
  as?: string // To handle the "as" prop like "select"
}

const FormField: React.FC<FormFieldProps> = ({
  suffix,
  label,
  name,
  placeholder,
  component,
  options,
  required,
  style,
  icon,
  as,
}) => {
  return (
    <FieldWrapper style={style}>
      <FieldLabel htmlFor={name} required={required} style={{ marginBottom: '10px' }}>
        {label}
      </FieldLabel>
      <FieldInputWrapper>
        <Field
          name={name}
          placeholder={placeholder ? String(placeholder) : undefined}
          component={component}
          as={as}
          options={options}
          icon={icon}
          suffix={suffix}
          required
        />
      </FieldInputWrapper>
    </FieldWrapper>
  )
}

export default FormField
