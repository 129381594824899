import styled from 'styled-components'
import { Button } from 'antd'
import { Input as InputBase } from 'antd'

export const StyledInput = styled(InputBase)`
  margin-bottom: 25px;
  border: 2px solid #ff00000;
  background: #ff0000;

  input[disabled] {
    background: #ff0000;
  }
`

export const Label = styled.label`
  display: block;
  margin: 0px 0px 4px 12px;
`
export const Actions = styled.div`
  a {
    color: ${({ theme }) => theme.colours.primary};
  }

  > span {
    margin: 0 5px;
  }
`

export const ActionButton = styled(Button)`
  padding: 0;
  color: ${({ theme }) => theme.colours.primary};
`