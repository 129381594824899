import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ContentContainer } from 'common/components/ContentContainer'
import PaginatedTable from 'common/components/PaginatedTable'
import { Actions, ActionButton } from './styledComponents'
import {
  AggregatorsContainer,
  SectionHeader,
  SectionTitle,
} from './styledComponents'
import { Aggregator } from 'common/store/schema/aggregatorAction'
import { Modal } from 'antd'
import { RootState } from 'common/store/rootReducer'
import { updateAggregator, fetchAggregators } from 'common/store/aggregators/actions'

const { confirm } = Modal

const Aggregators : React.FC = () => {
  const dispatch = useDispatch()
  const aggregators = useSelector((state: RootState) => state.aggregator.aggregators)
  const totalAggregators = useSelector((state: RootState) => state.aggregator.totalAggregator)

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [sortBy, setSortBy] = useState("aggregatorName")
  const [order, setOrder] = useState("ASC")

  useEffect(() => {
    dispatch(fetchAggregators(currentPage, pageSize, sortBy, order))
  }, [dispatch, currentPage, pageSize, sortBy, order])

  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber)
  const handlePageSizeChange = (perPage: number) => {
    setCurrentPage(1)
    setPageSize(perPage)
  }

  const onSortChange = (sortBy: string, order: string | null) => {
    setSortBy(sortBy)

    if(order)
    setOrder(order)
  }

  const onFilterApplied = (filterField: string | null, filterValue: string | null) => {
    setCurrentPage(1)
  }

  //provide confirmation and update the data
  const showUpdateAggregatorConfirmation = (aggregatorId: number, aggregatorName: string, aggregatorEnabled: boolean, values: Aggregator) => {
    
    const enabledValue = aggregatorEnabled ? 'disable' : 'enable'
    confirm({
      title: 'Are you sure you want to ' + enabledValue + ' this aggregator?',
      content: 'Aggregator Name: ' + aggregatorName,
      okText: 'Yes',
      onOk() {
        const updatedValues = {
          ...values, aggregatorEnabled : !values.aggregatorEnabled
        }
        console.log(updatedValues)
        dispatch(updateAggregator(aggregatorId,updatedValues))
      }
    })
  }
  
  const columns = [
    {
      title: 'Aggregator Name',
      key: 'aggregatorName',
      dataIndex: 'aggregatorName',
      sorter: (a: any, b: any) => { return 0},
      width: 300,
    },
    {
      title: 'Aggregator Code',
      key: 'aggregatorCode',
      dataIndex: 'aggregatorCode',
      width: 360,
      sorter: (a: any, b: any) => { return 0}
    },
    {
      title: 'Aggregator Balance',
      key: 'aggregatorBalance',
      dataIndex: 'aggregatorBalance',
      width: 360,
    },
    {
      title: 'Aggregator Fees',
      key: 'aggregatorFee',
      dataIndex: 'aggregatorFee',
      width: 360,
      render:(text: string, record: any) =>{
        return (record.aggregatorFeeCurrency? (record.aggregatorFeeCurrency + " " + text) : "N/A")
      },
      sorter: (a: any, b: any) => { return 0}
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: string, record: any) => {

        const enabledValue = record.aggregatorEnabled ? 'Disable' : 'Enable' 
        return (
          <Actions>

            {(
              <>
                <ActionButton
                  onClick={() => showUpdateAggregatorConfirmation(record.id,record.aggregatorName,record.aggregatorEnabled,record)}
                  type='link'
                >
                  {enabledValue}
                </ActionButton>
              </>
            )}
          </Actions>
        )
      }
    }
  ]


  return (
    <AggregatorsContainer>
      <SectionHeader>
        <SectionTitle>
          Aggregators
        </SectionTitle>
      </SectionHeader>
      <ContentContainer>
        <PaginatedTable
          total={totalAggregators}
          loading={false}
          data={aggregators}
          columns={columns}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          currentPage={currentPage}
          pageSize={pageSize}
          onSortChange={onSortChange}
          onFilterApplied={onFilterApplied}
          rowKey='aggregatorCode'
        />
      </ContentContainer>
      </AggregatorsContainer>
  )
}

export default Aggregators
