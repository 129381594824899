import React, { ComponentType, FC, useEffect } from 'react'
import { useNavigate, PathRouteProps } from 'react-router-dom'
import { RoutePaths } from 'core/Router/routePaths'

interface PrivateRouteProps extends PathRouteProps {
  component: ComponentType<any>
  isAuthenticated?: boolean
}

const PrivateRoute: FC<PrivateRouteProps> = ({
  component: Component,
  isAuthenticated,
  ...rest
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuthenticated) {
      console.log("User is not authenticated, redirecting to login...");
      navigate(RoutePaths.LOGIN);
    }
  }, [isAuthenticated, navigate]);

  return isAuthenticated ? <Component {...rest} /> : null;
}

export default PrivateRoute
